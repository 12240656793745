<template>
  <div class="row page-titles">
    <div class="col-md-5 align-self-center">
      <h4 class="text-themecolor">
        <slot name="title">{{$parent.pageTitle}}</slot>
      </h4>
    </div>
    <div 
      v-if="!$parent.moduleRole('ViewOnly')"
      class="col-md-7 align-self-center text-right"
    >
      <div class="d-flex justify-content-end align-items-center">
        <template v-if="!noAdd">
          <router-link v-if="$parent.isList"
            :to="{name:$route.name, params:{pageSlug:'add'}}" 
            class="btn btn-info d-lg-block m-l-15">
            <i class="fa fa-plus-circle m-r-5"></i> 
            {{ menu === "mail" ? 'Tulis Pesan' : menu === 'delegasi' ? 'Buat Delegasi' : 'Tambah Data' }}
          </router-link>
          <router-link v-else
            :to="{name:$route.name}" 
            class="btn btn-info d-lg-block m-l-15">
            <i class="fas fa-list-ul m-r-5"></i> List {{$parent.pageTitle}}
          </router-link>
        </template>
        <template v-if="noAdd && !singlePage">
          <router-link v-if="!$parent.isList"
            :to="{name:$route.name}" 
            class="btn btn-info d-lg-block m-l-15">
            <i class="fas fa-list-ul m-r-5"></i> List {{$parent.pageTitle}}
          </router-link>
        </template>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    sortingBtn:Boolean,
    deleteBtn:Boolean,
    singlePage:Boolean,
    noAdd:Boolean,
    noHeaderEl:Boolean,
    menu: String
  }
}
</script>