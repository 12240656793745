<template>
  <div>
    <div class="container-fluid">
      <PageTitle noAdd />
      <div class="row" v-if="data.data.length > 0">
        <div class="col-lg-12">
          <div class="card notif-panel">
            <div class="row">
              <div 
                v-for="(v,k) in data.data" :key="k"
                class="col-lg-12 col-md-8 border-left"
              >
                <router-link :to="v.link">
                  <div 
                    class="card-body"
                    :style="(v.is_read=='N'?'background:#eee;border-bottom:1px solid #fff':'border-bottom:1px solid #eee')"
                    @click="doClickNotif(v)"
                  >
                    <div class="row">
                      <div class="col-md-9">
                        <div class="wrap_notification">
                          <h3 v-html="v.activity"></h3>
                          <p>
                            {{ v.created_date | moment("DD MMMM YYYY, HH:mm") }} WIB
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-lg-12">
                  <div class="pagination-flat float-right">
                    <Pagination :data="data" :limit="2" @pagination-change-page="doPaginate">
                      <span slot="prev-nav"><i class="icon-arrow-left"></i></span>
                      <span slot="next-nav"><i class="icon-arrow-right"></i></span>
                    </Pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="data.data === false">
        <div class="col-lg-12">
          <div class="card">
            <div class="justify-content-center py-4 d-flex text-center text-danger align-items-center">
              <b-spinner class="align-middle mr-3"></b-spinner>
              <strong>Loading</strong>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center pb-3" v-if="data.data.length <= 0">
        <div class="col-lg-12 text-center">
          <div class="card">
            <div class="img text-center">
              <img :src="Gen.baseUrl()+'/images/nodata.svg'" class="nodata-img" style="height: 120px; width: 140px;"/>
            </div>
            <h4 class="text-center">Belum ada Notifikasi</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Gen from '@libs/Gen'
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'

export default{
  extends:GlobalVue,
  components:{PageTitle},
  data(){
    return {
      idKey:'bn_id',
      Gen: Gen,
    }
  },
  mounted(){
    this.apiGet()
  },
  methods:{
    doClickNotif(val){
			if(val.is_read=='N'){
				this.loadingOverlay=true
				Gen.apiRest(
					"/do/read-notif",
					{
						data:{ id:val.id }
					},
					'POST'
				).then(()=>{
					this.loadingOverlay=false
					val.is_read="Y"
					this.$router.push(val.link)
				});
			}else{
				this.$router.push(val.link)
			}
		},
  },
  watch: {
    $route(){
      this.apiGet()
    }
  }
}
</script>

<style>
.notif-panel .card-body:hover{
  background: #edf1f5;
}
.notif-panel .wrap_notification h3{
  color:#000
}
.notif-panel .wrapication.notif h3 span{
  color:#523DA4
}
</style>